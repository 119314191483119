import Vue from 'vue';
import MainPage from './MainPage.vue';
import store from '@/store/store';
import 'whatwg-fetch';

Vue.config.productionTip = false;

const dummy = new Vue({
    store,
    render: (h) => h(MainPage),
});

/**
 * Somewhat equivalent to jQuery(document).ready
 */
function ready(fn: () => void) {
    if (document.readyState !== 'loading') {
        fn();
    } else if (document.addEventListener) {
        document.addEventListener('DOMContentLoaded', fn);
    } else {
        (document as any).attachEvent('onreadystatechange', () => {
            if (document.readyState !== 'loading') {
                fn();
            }
        });
    }
}

/**
 * Set up burger menu
 */
ready(() => {
    const burgerToggler = document.querySelector('.burger-menu__toggler');
    const burgerMenu = document.querySelector('.burger-menu');
    let open = false;

    if (!burgerToggler || !burgerMenu) {
        return;
    }

    burgerToggler.addEventListener('click', (e) => {
        open = !open;

        if (open) {
            burgerMenu.classList.add('burger-menu--active');
        } else {
            burgerMenu.classList.remove('burger-menu--active');
        }
    });
});
